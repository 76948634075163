import React from "react";


import Banner from '../../../Components/Banner';
import { EntSiteCover } from '../../../Components/entretenimiento/EntSiteCover';
import NavBar from '../../../Components/NavBar';

import MinigolfLogo from '../../../assets/imgs/entretenimiento/minigolf-logoblanco.png';

import VerButton from '../../../Components/VerButton';
import DarkModeWrapper from '../../../Components/DarkModeWrapper';
import Footer from '../../../Components/Footer';
import { OtherEnts } from '../../../Components/entretenimiento/OtherEnts';

import MiniGolf1 from '../../../assets/imgs/minigolf/minigolf-1.webp';
import MiniGolf2 from '../../../assets/imgs/minigolf/minigolf-2.webp';
import MiniGolf3 from '../../../assets/imgs/minigolf/minigolf-3.webp';
import MiniGolf4 from '../../../assets/imgs/minigolf/minigolf-4.webp';
import MiniGolf5 from '../../../assets/imgs/minigolf/minigolf-5.webp';
import MiniGolf6 from '../../../assets/imgs/minigolf/minigolf-6.webp';
import MiniGolf7 from '../../../assets/imgs/minigolf/minigolf-7.webp';

import { MobileMenu } from "../../../Components/MobileMenu";

function MiniGolf() {
    return (
        <>
        <MobileMenu/>
        <div className='centering-wrapper' style={{backgroundColor: '#47B371'}}>
            <div className='max-width-container'>
                
                <NavBar/>
                <EntSiteCover schedule={'De Martes a Domingo'}>
                    <div className='minigolf-logo'>
                        <img src={MinigolfLogo} alt="" className="minigolf-logo"/>
                    </div>
                </EntSiteCover>
            </div>
        </div>
        <div className='centering-wrapper'>
            <div className='max-width-container'>
                <div className='ent-site-body-wrapper'>
                    <div className='ent-site-header-wrapper'>
                        <div className='ent-site-title'>
                        ¡Hoyo en uno! Bienvenidos al MiniGolf by Ronda.
                        </div>
                        <div className='ent-site-description'>
                        El primer MiniGolf de Buenos Aires con una estética de otro planeta. 2 canchas, 9 hoyos. Un plan redondo y único para hacer con quien quieras!
                        </div>
                    </div>
                    {/*make it a component?*/}
                    <div className='ent-site-card-grid-wrapper'>
                        <div className='ent-col-1'>
                            <img style={{all: 'inherit'}} src={MiniGolf6} alt=""/>
                        </div>
                        <div className='ent-col-2'>
                            <div className='ent-col-2-row-1 square-radius'>
                                <img className='mosaic-image' src={MiniGolf4} alt=""/>
                            </div>
                            <div className='ent-col-2-row-2 square-radius'>
                                <img src={MiniGolf3} alt="" style={{all: 'inherit'}}/>
                            </div> 
                        </div>
                        <div className='ent-col-3'>
                            <div className='ent-col-3-row-1'>
                                <div className='ent-col-3-row-1-col-1 square-radius'>
                                    <img src={MiniGolf2} alt="" style={{all: 'inherit'}}/>
                                </div>
                                <div className='ent-col-3-row-1-col-2 square-radius'>
                                    <img src={MiniGolf5} alt="" style={{all: 'inherit'}}/>
                                </div>
                            </div>
                            <div className='ent-col-3-row-2 square-radius' style={{objectPosition: '10% 50%'}}>
                                <img src={MiniGolf7} alt="" style={{all: 'inherit'}}/>
                            </div>
                        </div>
                    </div>
                    {/*/component*/}
                    



                </div>
            </div>
        </div>
        <div className='centering-wrapper' style={{backgroundColor: '#F0F0F0', marginBottom: '-20px'}}>
            <div className='max-width-container'>
                <OtherEnts inMiniGolf={true}/>
            </div>
        </div>
        <DarkModeWrapper>
            <Footer entSite={true}/>
        </DarkModeWrapper>

        </>
    )
}

export default MiniGolf;