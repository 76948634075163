import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Landing from './Pages/Landing/Landing';
import Gastronomia from './Pages/Gastronomia/Gastronomia';
import Home from './Pages/Home/Home';
import Arte from './Pages/Arte/Arte';
import Moda from './Pages/Moda/Moda';
import Entretenimiento from './Pages/Entretenimiento/Entretenimiento';
import Paloko from './Pages/Entretenimiento/sites/Paloko';
import MiniGolf from './Pages/Entretenimiento/sites/MiniGolf';
import ObrasBreves from './Pages/Entretenimiento/sites/ObrasBreves';
import Eventos from './Pages/Eventos/Eventos';
import QuienesSomos from './Pages/Quienes_Somos/QuienesSomos';
import { MenuQR } from './Pages/MenuQr/MenuQR';

import { Proximamente } from './Pages/Proximamente/Proximamente';
//Proximamente type={"obras-breves"}/>
function App() {
  return (
    <Router>
      <Routes>
        <Route exact path={'/'} element={<Landing/>}/>
        <Route exact path={'/home'} element={<Home/>}/>
        <Route exact path={'/gastronomia'} element={<Gastronomia/>}/>
        <Route exact path={'/arte'} element={<Arte/>}/>
        <Route exact path={'/moda'} element={<Moda/>}/>
        <Route exact path={'/entretenimiento'} element={<Entretenimiento/>}/>
        <Route exact path={'/entretenimiento/paloko'} element={<Paloko/>}/>
        <Route exact path={'/entretenimiento/minigolf'} element={<MiniGolf/>}/>
        <Route exact path={'/entretenimiento/obras-breves'} element={<ObrasBreves/>}/>
        <Route exact path={'/eventos'} element={<Eventos/>}/>
        <Route exact path={'/quienes-somos'} element={<QuienesSomos/>}/>
        <Route exact path={'/menuqr/:name'} element={<MenuQR/>}/>
      </Routes>
    </Router>
  );
}

export default App;
